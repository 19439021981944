import React from 'react';
import Header from '../includes/Header.jsx';
import Footer from '../includes/Footer.jsx';
import './style.css'


const TermsOfUsePage = ({ loginstate, onSignOut, user }) => {
    /**
    * Пользовательское соглашение
    */

    return (
        <body>
            <header className="container-fluid">
                <Header loginstate={loginstate} onSignOut={onSignOut} user={user}/>
            </header>
            <main className="container px4">
                    
                <div class="row mb-3">
                    <div class="col" style={{border: 'solid 2px #eee', padding: '20px'}}>
                        <div id="inputResult">
                            <div class="row mb-4">
                                <div class="col">
                                    <h4><strong>Пользовательское соглашение</strong></h4>
                                </div>
                            </div>
                            <div class="row mb-4 text-start">
                                <div class="col">
                                    <h5>1. Пользовательское Соглашение</h5>
                                    <div class="descr">
                                        Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем https://offercreator.ru/ (далее Генератор КП или Администрация) с одной стороны и пользователем сайта с другой.
                                        Сайт Генератор КП не является средством массовой информации.
                                    </div>
                                    <div class="ol">
                                        <div class="li">
                                            1.1. Используя сайт, Вы соглашаетесь с условиями данного соглашения.
                                        </div>
                                        <div class="li">
                                            1.2. Если Вы не согласны с условиями данного соглашения, не используйте сайт Генератор КП!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4 text-start">
                                <div class="col">
                                    <h5>2. Предмет соглашения</h5>
                                    <div class="descr">
                                        Администрация предоставляет пользователю право на размещение на сайте следующей информации:
                                    </div>
                                    <div class="ol">
                                        <div class="li">
                                            2.1. - Текстовой информации.
                                        </div>
                                        <div class="li">
                                            2.2. - Фотоматериалов
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4 text-start">
                                <div class="col">
                                    <h5>3. Права и обязанности сторон</h5>
                                    <div class="ol">
                                        <div class="li">3.1. Пользователь имеет право:</div>
                                        <div class="li">
                                            - осуществлять поиск информации на сайте
                                        </div>
                                        <div class="li">
                                            - получать информацию на сайте
                                        </div>
                                        <div class="li">
                                            - создавать информацию для сайта
                                        </div>
                                        <div class="li">
                                            - комментировать контент, выложенный на сайте
                                        </div>
                                        <div class="li">
                                            - копировать информацию на другие сайты с указанием источника
                                        </div>
                                        <div class="li">
                                            - использовать информацию сайта в личных некоммерческих целях
                                        </div>
                                        <div class="li">3.2. Администрация имеет право:</div>
                                        <div class="li">
                                            - по своему усмотрению и необходимости создавать, изменять, отменять правила
                                        </div>
                                        <div class="li">
                                            - ограничивать доступ к любой информации на сайте
                                        </div>
                                        <div class="li">
                                            - создавать, изменять, удалять информацию
                                        </div>
                                        <div class="li">
                                            - удалять учетные записи
                                        </div>
                                        <div class="li">
                                            - отказывать в регистрации без объяснения причин
                                        </div>
                                        <div class="li">3.3. Пользователь обязуется:</div>
                                        <div class="li">
                                            - обеспечить достоверность предоставляемой информации
                                        </div>
                                        <div class="li">
                                            - не распространять информацию, которая направлена на пропаганду войны, разжигание национальной, расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена уголовная или административная ответственность
                                        </div>
                                        <div class="li">
                                            - не нарушать работоспособность сайта
                                        </div>
                                        <div class="li">
                                            - не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу
                                        </div>
                                        <div class="li">
                                            - не совершать действия, направленные на введение других Пользователей в заблуждение
                                        </div>
                                        <div class="li">
                                            - не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам
                                        </div>
                                        <div class="li">
                                            - не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ
                                        </div>
                                        <div class="li">
                                            - не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ
                                        </div>
                                        <div class="li">
                                            - не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами
                                        </div>
                                        <div class="li">3.4. Администрация обязуется:</div>
                                        <div class="li">
                                            - поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4 text-start">
                                <div class="col">
                                    <h5>4. Ответственность сторон</h5>
                                    <div class="ol">
                                        <div class="li">
                                            - пользователь лично несет полную ответственность за распространяемую им информацию
                                        </div>
                                        <div class="li">
                                            - администрация не несет никакой ответственности за достоверность информации, скопированной из других источников
                                        </div>
                                        <div class="li">
                                            - администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг
                                        </div>
                                        <div class="li">
                                            - администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами
                                        </div>
                                        <div class="li">
                                            - в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.)
                                        </div>
                                        <div class="li">
                                            Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4 text-start">
                                <div class="col">
                                    <h5>4. Условия действия Соглашения</h5>
                                    <div class="ol">
                                        <div class="li">
                                            Данное Соглашение вступает в силу при любом использовании данного сайта.
                                        </div>
                                        <div class="li">
                                            Соглашение перестает действовать при появлении его новой версии.
                                        </div>
                                        <div class="li">
                                            Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.
                                        </div>
                                        <div class="li">
                                            Администрация не оповещает пользователей об изменении в Соглашении.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <footer className="container-fluid">
                <Footer/>
            </footer>
        </body>
      );
    };
    
    export default TermsOfUsePage;